// school config initial setting
const schoolObj = {
studentInfo:{birthPlace:[true,false],birthday:[true,false],firstName:[true,false],gender:[true,false],lastName:[true,false],middleName:[true,false],nationalId:[true,false],nationality:[true,false],religion:[true,false],previousSchool:[true,false]},
medicalInfo:{allergies:[true,false],diseases:[true,false],glasses:[true,false],otherNote:[true,false]},
otherInfo:{degree:[true,false],email:[true,false],fatherInfo:true,firstName:[true,false],job:[true,false],lastName:[true,false],middleName:[true,false],motherInfo:true,phone:[true,false]},
document:{cob:[true,false],lastGrade:[true,false],parentId:[true,false],photo:[true,false],schoolCertificate:[true,false],parentPassport:[true,false],studentPassport:[true,false],others:[true,false]},
setting:{campus:["campus1","campus2"],devisions:["British","American","internaltional"],gradeApplying:["2001/2002"],admissionEmail:'',admissionPhone:'',mainColor:'#0F467A',termUrl:'https://www.selynk.com/'}};
// company setting initial setting
const companySetting = {
    aboutImage: "",
    aboutTitle: "",
    aboutUs: true,
    companyGallery: true,
    map: true,
    displayBanner: true,
    displayProductDesc: true,
    phone: true,
    relatedProduct: true,
    socialMedia: true,
    teamSection: true,
    workingHour: true,
    backgroundColor: "",
    bannerStyle: "boxed",
    bannerTitle: "",
    banner: "",
    description: "",
    fontDarkColor: "#1324ea",
    fontLightColor: "#e5cece",
    bannerFontColor: "",
    mainColor: "#14e733",
    maxDisplayItems: null,
    productType: "",
    transparency: "",
    transparentLayer: ""
};

const INITIAL_STATE = {
    messages:[], // active chat message List
    user:null, // auth user data
    company:null, // auth company data
    reauth:false, // reauth user by matching the password with company company
    schoolId:null, // schoolId
    lang:"en", // lang
    status: {status:true,resolve:false}, // check auth company approved status
    subjectAndCur: [], // subject and curriculum array
    cartList: [], // An array of reservation which are added as a cart in route /booking
    serviceCartList: [], // An array of service which are added as a cart in route /booking
    cartId:'', // user cartId from DB
    permission: {}, // permission object of auth partner member
    isLogin:false, // Login Boolean
    pm: null, // auth parent email
    pnum: null, // auth parent phone number
    par_ws: null, // parent wallet info
    company_id:null, // CompanyId for parent login
    schoolConfig:schoolObj, // school setting for route /school-admission/:schoolId
    cpConfig: companySetting, // default company theme setting
    AC_IID: null, // Active chat Id
    AC_TP: null, // Active chat type
    plans:{}, // Subscription plan
    planId: null, // Partner plan Id
    adminDemo: false, // admin access partner dashboard
    dialog:false,
    ugardeDlg:false,
    companyDomain:false, // check if domain is company own
    sclRep: [], // school report list to render in application dialog,
    parentLoc: {}, // parent location detail like city, countryCode and full address,
    uSession: null, // parent current session
    singleSession: true,
    totalServices: null, // total number of company services
    totalCourses: null, // total number of company courses
    prtpIn: null, // parent temp info like email and uid used to send with pin verification
    sessionDuration: null, // record partner session
    isIframe:false, //check if window is iframe or not for teamMember login
}

const reducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case "SET_USER": {
            return {...state, user: action.data};
        }
        case "SET_COMPANY": {
            return {...state, company: action.data};
        }
        case "SET_COMPANYID": {
            return {...state, company_id: action.data};
        }
        case "SET_REAUTH": {
            return {...state, reauth: action.data};
        }
        case "SET_PLANS": {
            return {...state, plans: action.data};
        }
        case "SET_PARTNER_PLANID": {
            return {...state, planId: action.data};
        }
        case "SET_PARENT_WALLET": {
            return {...state, par_ws: action.data};
        }
        case "SET_PARTNER_SESSION": {
            return {...state, sessionDuration: action.data};
        }
        case "UPDATE_CHATID": {
            return {...state, AC_IID: action.data};
        }
        case "SET_PARTNER_TOTAL_SERVICE": {
            return {...state, totalServices: action.data};
        }
        case "SET_PARTNER_TOTAL_COURSE": {
            return {...state, totalCourses: action.data};
        }
        case "UPDATE_CHAT_TYPE": {
            return {...state, AC_TP: action.data};
        }
        case "UPDATE_PARENT_SESSION": {
            return {...state, uSession: action.data};
        }
        case "SET_PARENT_ACTIVE_SESSION": {
            return {...state, singleSession: action.data };
        }
        // update parent reservation cart
        case "UPDATE_CART": {
            return {...state, cartList: action.data};
        }
        case "UPDATE_CART_DATA": {
            return {...state, cartList: action.data['cartList'], serviceCartList: action.data['serviceCartList'], cartId: action.data['cartId']};
        }
        case "SET_PARENT_LOCATION": { // save school config
            return {...state, parentLoc: action.data};
        }
        case "SET_CARTID": {
            return {...state, cartId: action.data};
        }
        case "UPDATE_SERVICE_CART": {
            return {...state, serviceCartList: action.data};
        }
        case "SET_LOGINSTATUS": {
            return {...state, isLogin: action.data};
        }
        // set parent email while registering
        case "SET_PM": {
            return {...state, pm: action.data};
        }
        // set parent phone number
        case "SET_PNUM": {
            return {...state, pnum: action.data};
        }
        case "CHANGE_LANG": {
            return {...state, lang: action.data};
        }
        case "UPDATE_STATUS": {
            return {...state, status: action.data};
        }
        case "SET_TEMP_PARENT_INFO": {
            return {...state, prtpIn: action.data};
        }
        case "GET_SUBJECT": {
            return {...state, subjectAndCur: action.data};
        }
        case "GET_PERMISSION": {
            return {...state, permission: action.data};
        }
        case "SCHOOL_ID": {
            return {...state, schoolId: action.data};
        }
        case "SET_COMPANYDOMAIN": {
            return {...state, companyDomain: action.data};
        }
        // SCHOOL REPORT LIST
        case "SCHOOL_REPORT_LIST": {
            return {...state, sclRep: action.data};
        }
        case "SET_SCHOOL_CONFIG": { // save school config
            return {...state, schoolConfig: action.data};
        }
        case "SET_COMPANY_CONFIG": { // save school config
            return {...state, cpConfig: action.data};
        }
        case "SET_DIALOG": { // save school config
            return {...state, dialog: action.data};
        }
        case "SET_PARTNER_PLAN_EXDATE":{
            return {...state, ugardeDlg: action.data};
        }
        case "SET_ADMIN_DEMO": { // save school config
            return {...state, adminDemo: action.data};
        }
        case "SET_IFRAME": { // save iframe condition for team member login
            return {...state, isIframe: action.data};
        }
        case "CLOSE_ADMIN_DEMO": { // save school config
            return {...state, adminDemo: false, company:null,ugardeDlg:false};
        }
        case "REMOVE_USER": {
            return {...state, user: null,company:null,uSession:null,isIframe:false,sessionDuration:null,totalServices: null, totalCourses: null,singleSession: true,prtpIn:null,ugardeDlg:false,cartId:'',companyDomain:false,sclRep:[],company_id:null,adminDemo:false,reauth:false,par_ws:null,AC_IID:null,cartList:[],serviceCartList:[],messages:[],lang:"en",schoolId:null,cpConfig:companySetting,status:{status:true,resolve:false},subjectAndCur:[],permission:{},isLogin:false,pm:null,pnum:null,schoolConfig:schoolObj,planId:null};
        }
        case "REMOVE_PARENT_USER": {
            return {...state, user: null,cartId:'',ugardeDlg:false,isIframe:false,prtpIn:null,sessionDuration:null,adminDemo:false,par_ws:null,AC_IID:null,cartList:[],serviceCartList:[],messages:[],lang:"en",schoolId:null,cpConfig:companySetting,status:{status:true,resolve:false},subjectAndCur:[],permission:{},isLogin:false,pm:null,pnum:null,schoolConfig:schoolObj};
        }
        case "UPDATE_MESSAGES": {
            return {...state, messages: action.data};
        }
        default: {
            return state;
        }
    }
}

export default reducer;